<div class="flex flex-col space-y-4 w-full max-w-3xl">
  <p class="font-semibold text-base">SQL: {{ sqlSelected }}</p>
  <form [formGroup]="sqlIntegrationForm">
    @if (loadingSqlForm) {
      <div
        class="w-full h-[calc(100vh-300px)] flex items-center justify-center"
      >
        <app-loading-indicator />
      </div>
    } @else {
      <section class="flex flex-col space-y-4 mb-4">
        <ng-container
          [ngTemplateOutlet]="sectionHeader"
          [ngTemplateOutletContext]="{ label: 'Database', icon: 'fa-database' }"
        />
        <div
          class="flex flex-col lg:flex-row lg:justify-between lg:items-center"
        >
          <p class="font-medium text-primary-400 text-sm">Database*</p>
          <mat-form-field
            appearance="outline"
            subscriptSizing="dynamic"
            class="w-full lg:w-80"
          >
            <mat-label class="ml-[24px]">Database</mat-label>
            <div class="flex flex-row items-center">
              <ng-container
                [ngTemplateOutlet]="loadingSpinner"
                [ngTemplateOutletContext]="{ isLoading: showDatabaseLoading }"
              />
              <mat-select
                formControlName="dataBase"
                (selectionChange)="databaseChange()"
              >
                @for (integration of integrationsSqlList; track $index) {
                  <mat-option [value]="integration.rithmId">{{
                    integration.name
                  }}</mat-option>
                }
              </mat-select>
            </div>
          </mat-form-field>
        </div>
        <div
          class="flex flex-col lg:flex-row lg:justify-between lg:items-center"
        >
          <p class="font-medium text-primary-400 text-sm">Table*</p>
          <mat-form-field
            appearance="outline"
            subscriptSizing="dynamic"
            class="w-full lg:w-80"
          >
            <mat-label class="ml-[24px]">Table</mat-label>
            <div class="flex flex-row items-center">
              <ng-container
                [ngTemplateOutlet]="loadingSpinner"
                [ngTemplateOutletContext]="{ isLoading: showTableLoading }"
              />
              <mat-select
                formControlName="tableName"
                (selectionChange)="tableNameChange()"
              >
                @for (table of tablesList; track $index) {
                  <mat-option [value]="table">{{ table }}</mat-option>
                }
              </mat-select>
            </div>
          </mat-form-field>
        </div>
      </section>
      <section class="flex flex-col space-y-4 mb-4">
        <ng-container
          [ngTemplateOutlet]="sectionHeader"
          [ngTemplateOutletContext]="{
            label: 'Key',
            icon: 'fa-key',
            help: true,
            helpText: 'Main key and the associated field for its integration'
          }"
        />
        <div
          class="flex justify-between items-center flex-col lg:flex-row"
          formGroupName="keyField"
        >
          <section class="flex flex-col space-y-2 w-full lg:w-80 shrink-0">
            <p class="font-medium text-primary-400 text-sm">Key Value</p>
            <mat-form-field
              appearance="outline"
              subscriptSizing="dynamic"
              class="w-full"
            >
              <mat-label class="ml-[24px]">Column</mat-label>
              <div class="flex flex-row items-center">
                <ng-container
                  [ngTemplateOutlet]="loadingSpinner"
                  [ngTemplateOutletContext]="{ isLoading: showColumnLoading }"
                />
                <mat-select
                  formControlName="columnKey"
                  (selectionChange)="columnKeyChange(true)"
                >
                  @for (column of columnsOfTablesIntegrationSql; track $index) {
                    <mat-option [value]="column.property">{{
                      column.property
                    }}</mat-option>
                  }
                </mat-select>
              </div>
            </mat-form-field>
          </section>
          <section
            class="flex items-center lg:h-14 self-end w-full justify-center p-2"
          >
            <i
              class="fa-light fa-arrow-right text-xl text-secondary-400 hidden lg:inline-block"
            ></i>
          </section>
          <section class="flex items-center space-y-2 w-full lg:w-80 shrink-0">
            <div class="w-[calc(100%-50px)]">
              <p class="font-medium text-primary-400 text-sm mb-2">Field</p>
              @if (!isCustomField) {
                <section>
                  <mat-form-field
                    appearance="outline"
                    subscriptSizing="dynamic"
                    class="w-full"
                  >
                    <mat-label class="ml-[24px] text-lg"
                      >Previous field</mat-label
                    >
                    <div class="flex flex-row items-center">
                      <span>
                        <i
                          class="fal fa-magnifying-glass text-xl text-secondary-300"
                        ></i>
                      </span>
                      <mat-select
                        (selectionChange)="fieldChange()"
                        id="question-field-selector"
                        data-testid="question-field-selector"
                        formControlName="fieldKey"
                      >
                        @for (
                          field of filteredQuestionsForKeyField;
                          track $index
                        ) {
                          <mat-option
                            id="question-field-option-{{ field.rithmId }}"
                            attr.data-testid="question-field-option-{{
                              field.rithmId
                            }}"
                            [value]="'rid:' + field.rithmId"
                          >
                            {{ field.prompt }}
                          </mat-option>
                        }
                      </mat-select>
                    </div>
                  </mat-form-field>
                </section>
              } @else {
                <ng-container [ngTemplateOutlet]="customFields" />
              }
            </div>
            <ng-template #customFields>
              <div
                class="custom-field w-full flex"
                [formGroup]="customFieldForm"
              >
                @switch (filteredByIntegrationType) {
                  @case ('number') {
                    <app-number-field
                      class="w-full"
                      [field]="field"
                      [isAction]="true"
                      formControlName="numberFieldForm"
                    />
                  }
                  @case ('date') {
                    <app-date-field
                      class="w-full"
                      [field]="field"
                      [isAction]="true"
                      [setTimeSettings]="true"
                      [setMinDate]="true"
                      [minDate]="minDate"
                      formControlName="dateFieldForm"
                    />
                  }
                  @case ('boolean') {
                    <app-check-field
                      class="w-full"
                      [widgetItem]="true"
                      [field]="field"
                      formControlName="checkFieldForm"
                    />
                  }
                  @default {
                    <app-text-field
                      class="w-full"
                      [field]="field"
                      [isStation]="false"
                      [isAction]="true"
                      [labelTag]="'Field'"
                      formControlName="textFieldForm"
                    />
                  }
                }
              </div>
            </ng-template>
            <button
              mat-stroked-button
              type="button"
              (click)="changeCustomField()"
              data-testid="button-switch-field"
              id="button-switch-field"
              class="value-switching-button"
            >
              <i
                class="fa-solid text-accent-500 fa-xl"
                [ngClass]="[isCustomField ? 'fa-angle-down' : 'fa-keyboard']"
              ></i>
            </button>
          </section>
        </div>
      </section>
      <section class="flex flex-col space-y-4" formArrayName="CustomValue">
        <ng-container
          [ngTemplateOutlet]="sectionHeader"
          [ngTemplateOutletContext]="{
            label: 'Column Value',
            icon: 'fa-table',
            help: true,
            helpText: 'Column key and associated field for integration'
          }"
        />
        @for (pair of columnValuePairs; track $index) {
          <div
            class="border-b lg:border-b-0 pb-4 lg:pb-0 border-secondary-400 flex justify-between items-center flex-col lg:flex-row relative"
            [formGroupName]="$index"
          >
            <section class="flex flex-col space-y-2 w-full lg:w-80 shrink-0">
              <p class="font-medium text-primary-400 text-sm">Key Value</p>
              <mat-form-field
                appearance="outline"
                subscriptSizing="dynamic"
                class="w-full"
              >
                <mat-label class="ml-[24px]">Column</mat-label>
                <div class="flex flex-row items-center">
                  <ng-container
                    [ngTemplateOutlet]="loadingSpinner"
                    [ngTemplateOutletContext]="{
                      isLoading: showColumnLoading
                    }"
                  />
                  <mat-select
                    formControlName="columnCustom"
                    (selectionChange)="columnCustomChange($index)"
                  >
                    @for (column of columnsList; track $index) {
                      <mat-option [value]="column.property">{{
                        column.property
                      }}</mat-option>
                    }
                  </mat-select>
                </div>
              </mat-form-field>
            </section>
            <section
              class="flex items-center lg:h-14 self-end w-full justify-center p-2"
            >
              <i
                class="fa-light fa-arrow-right text-xl text-secondary-400 hidden lg:inline-block"
              ></i>
            </section>
            <section
              class="flex flex-col space-y-2 w-full lg:w-80 shrink-0 custom-field"
            >
              <p class="font-medium text-primary-400 text-sm">Field</p>
              <mat-form-field
                appearance="outline"
                subscriptSizing="dynamic"
                class="w-full"
              >
                <mat-label>Field</mat-label>
                <div class="flex flex-row items-center">
                  <mat-select formControlName="fieldCustom">
                    @for (
                      field of filteredQuestionsForCustomField[$index];
                      track $index
                    ) {
                      <mat-option [value]="'rid:' + field.rithmId">{{
                        field.prompt
                      }}</mat-option>
                    }
                  </mat-select>
                </div>
              </mat-form-field>
            </section>
            @if (columnValuePairs.length > 1) {
              <div
                class="absolute -right-10 h-16 lg:h-14 bottom-0 lg:flex items-center bg-white"
              >
                <button
                  mat-icon-button
                  type="button"
                  (click)="removeField($index)"
                >
                  <i class="fal fa-trash text-secondary-400"></i>
                </button>
              </div>
            }
          </div>
        }

        <div class="w-full text-right">
          <button
            type="button"
            mat-stroked-button
            class="flex !space-x-3 w-full lg:w-80"
            (click)="addField()"
            [disabled]="!allCustomValueFilled"
          >
            <i class="fal fa-plus"></i>
            <span class="px-2">FIELD</span>
          </button>
        </div>
      </section>
    }
  </form>
</div>
<!-- Help text. -->
<ng-template
  #sectionHeader
  let-icon="icon"
  let-label="label"
  let-help="help"
  let-helpText="helpText"
>
  <div
    class="h-[52px] w-full bg-secondary-50 p-4 flex space-x-4 text-secondary-500 rounded-md"
  >
    <i class="fal" [ngClass]="icon"></i>
    <p class="grow text-sm">{{ label }}</p>
    @if (help) {
      <i
        class="fal fa-question-circle"
        [matTooltip]="helpText"
        matTooltipPosition="right"
      ></i>
    }
  </div>
</ng-template>
<!-- Loading fields SQL. -->
<ng-template #loadingSpinner let-isLoading="isLoading">
  @if (isLoading) {
    <div class="flex flex-col">
      <app-loading-indicator [diameter]="20" />
    </div>
  } @else {
    <span>
      <i class="fal fa-magnifying-glass text-xl text-secondary-300"></i>
    </span>
  }
</ng-template>
