<section
  class="append-field-section max-w-md"
  [formGroup]="formAppendFieldAction"
>
  <h6 class="mb-4 text-lg">Append field:</h6>
  <section class="base-field-section flex flex-col py-3">
    <span class="font-medium text-primary-500 text-sm">Base Field:</span>
    <span class="text-xs text-primary-300 pt-1"
      >Start by selecting a field you’d like to append a value to</span
    >
    <div class="flex flex-col pt-4">
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label>append to</mat-label>
        <span matPrefix
          ><i class="fa-light fa-search text-primary-300"></i
        ></span>
        <span matSuffix (click)="filterBucketQuestions()"
          ><i
            class="fa-light fa-chevron-down text-primary-300 cursor-pointer"
          ></i
        ></span>
        <input
          matInput
          placeholder="append to"
          formControlName="target"
          [matAutocomplete]="auto"
          (click)="filterBucketQuestions()"
        />
        <mat-autocomplete
          #auto="matAutocomplete"
          [displayWith]="displayPromptSelected"
        >
          @for (question of questionsToFilter$ | async; track $index) {
            <mat-option [value]="question.rithmId">
              {{ question.prompt }}
            </mat-option>
          }
        </mat-autocomplete>
        @if (!filteredQuestions.length) {
          <mat-hint class="text-xs text-error-500"
            >There are not available fields to select.</mat-hint
          >
        }
      </mat-form-field>
    </div>
  </section>

  <section
    formGroupName="source"
    class="format-space-box flex justify-between items-center py-3"
  >
    <div>
      <p class="font-medium text-primary-500 text-sm">Formatted Spacing:</p>
      <p class="text-xs text-primary-300 pt-1">
        Include spacing between each appended item
      </p>
    </div>
    <mat-slide-toggle formControlName="includeSpacing" ngDefaultControl />
  </section>

  <ng-container [ngTemplateOutlet]="appendValuesSection" />
</section>
<ng-container [ngTemplateOutlet]="appendFieldButtonSection" />

<!-- Append values section. -->
<ng-template #appendValuesSection>
  <section class="append-value-section flex flex-col py-3">
    <span class="font-medium text-primary-500 text-sm pb-2"
      >Appended value(s):</span
    >
    @for (
      value of appendFieldValues;
      track $index + value;
      let i = $index;
      let f = $first
    ) {
      <div class="flex items-center space-x-2 pb-4">
        <app-appended-field
          class="flex w-full"
          [class.first-of-field]="f"
          [appendedValue]="value"
          [questionsList]="appendValueQuestionList"
          [disableAppendValue]="!formAppendFieldAction.value.target"
          [showAssignedTo]="isTextField"
          (editedAppendedField)="editValue($event, i)"
          (removeAppendedField)="removeValue($event, i)"
          (validateEmptyValue)="validateEmptyValue($event, i)"
          (resetAppend)="resetAppend(i)"
          (isCustomValue)="isCustomValueEvent($event)"
        />
      </div>
    }

    <button
      mat-stroked-button
      type="button"
      color="accent"
      id="add-field"
      class="min-w-[340px]"
      [disabled]="disableAddField || !formAppendFieldAction.valid"
      (click)="addAppendField()"
    >
      <i class="fa-regular fa-plus fa-sm"></i>
      <span class="font-semibold ml-1">ADD FIELD</span>
    </button>
  </section>
</ng-template>

<!-- Display cancel and done buttons for Append Field. -->
<ng-template #appendFieldButtonSection>
  <section class="relative flex justify-between items-center py-2 max-w-none">
    <div class="flex space-x-4">
      <button
        mat-button
        type="button"
        id="append-field-cancel"
        [disabled]="(savingAction || deletingAction) && orderOfOperations"
        data-testid="append-field-cancel"
        (click)="onCancelAppendField()"
      >
        <span class="font-semibold">CANCEL</span>
      </button>
      <button
        mat-raised-button
        [ngClass]="{
          '!bg-sky-500 !text-white !font-semibold': orderOfOperations,
          '!opacity-60': disableAddField || !formAppendFieldAction.valid
        }"
        type="button"
        color="accent"
        id="append-field-save"
        data-testid="append-field-save"
        [disabled]="
          disableAddField ||
          !formAppendFieldAction.valid ||
          savingAction ||
          deletingAction
        "
        (click)="addActionToPower()"
      >
        <span class="font-semibold">
          @if (orderOfOperations) {
            @if (savingAction) {
              <ng-container
                [ngTemplateOutlet]="loadingInlineText"
                [ngTemplateOutletContext]="{ $implicit: 'Saving...' }"
              ></ng-container>
            } @else {
              SAVE
            }
          } @else {
            DONE
          }
        </span>
      </button>
    </div>
    @if (orderOfOperations && editingAction) {
      <div class="flex-grow text-right">
        <button
          mat-stroked-button
          class="!bg-red-500 !text-white"
          type="button"
          id="delete-action"
          data-testid="delete-action"
          (click)="removeParentAction()"
        >
          @if (deletingAction) {
            <ng-container
              [ngTemplateOutlet]="loadingInlineText"
              [ngTemplateOutletContext]="{ $implicit: 'Deleting...' }"
            ></ng-container>
          } @else {
            <i class="fa-light fa-trash text-lg"></i> DELETE ACTION
          }
        </button>
      </div>
    }
  </section>
</ng-template>

<!-- In line text loading indicator -->
<ng-template #loadingInlineText let-message>
  <app-loading-indicator
    id="loading-inline-text"
    [inlineText]="message"
  ></app-loading-indicator>
</ng-template>
