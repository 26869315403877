<div class="w-full flex flex-col gap-4 pt-4 max-w-lg">
  <section class="w-full">
    <p class="text-md font-semibold tracking-wide">Generate Response</p>
    <span class="text-secondary-500 text-xs leading-none tracking-wide"
      >Update a field with a response from ChatGPT</span
    >
  </section>

  <form
    [formGroup]="integrationForm"
    ngForm="form"
    class="w-full flex flex-col gap-4"
  >
    <div class="w-full flex gap-2">
      @if (!isCustomField) {
        <mat-form-field
          appearance="outline"
          class="w-full"
          subscriptSizing="dynamic"
        >
          <mat-label>Prompt</mat-label>
          <mat-select
            formControlName="prompt"
            id="prompt-field"
            data-testid="prompt-field"
            (selectionChange)="selectPromptField($event)"
          >
            @for (option of filteredQuestions; track $index) {
              <mat-option
                [value]="option.rithmId"
                [id]="'prompt-option-' + option.rithmId"
                [attr.data-testid]="'prompt-option-' + option.rithmId"
              >
                {{ option.prompt }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      } @else {
        <ng-container [ngTemplateOutlet]="customFields" />
      }
      <ng-template #customFields>
        <mat-form-field
          appearance="outline"
          class="w-full"
          subscriptSizing="dynamic"
        >
          <mat-label>Prompt</mat-label>
          <input
            matInput
            placeholder="Placeholder"
            id="prompt"
            data-testid="prompt"
            formControlName="prompt"
          />
        </mat-form-field>
      </ng-template>

      <button
        mat-stroked-button
        type="button"
        class="!h-14 !w-14"
        id="custom-field-toggle"
        data-testid="custom-field-toggle"
        (click)="resetValues()"
      >
        <i
          class="fa-solid text-accent-500 fa-xl"
          [ngClass]="[isCustomField ? 'fa-keyboard' : 'fa-angle-down']"
        ></i>
      </button>
    </div>
    <div class="w-full flex gap-2">
      <mat-form-field
        appearance="outline"
        class="w-full"
        subscriptSizing="dynamic"
      >
        <mat-label>Response Field</mat-label>
        <mat-select
          formControlName="fieldRithmId"
          id="response-field"
          data-testid="response-field"
          (selectionChange)="selectResponseField($event)"
        >
          @for (option of filteredQuestions; track $index) {
            <mat-option
              [value]="option.rithmId"
              [id]="'response-field-' + option.rithmId"
              [attr.data-testid]="'response-field-' + option.rithmId"
            >
              {{ option.prompt }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</div>
