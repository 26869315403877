import { CommonModule } from '@angular/common';
import {
  Component,
  effect,
  EventEmitter,
  input,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

import { CustomField, Question, QuestionFieldType } from 'src/models';

/**
 * Component for the fields to add.
 */
@Component({
  selector: 'app-appended-field[appendedValue]',
  templateUrl: './appended-field.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    MatButtonModule,
  ],
  styleUrls: ['./appended-field.component.scss'],
})
export class AppendedFieldComponent implements OnInit {
  /** Value added. */
  @Input() appendedValue = '';

  /** Whether to show or not assigned to field. */
  showAssignedTo = input.required<boolean>();

  /** List of questions modified. */
  _questionsList: Question[] = [];

  /** List of questions. */
  @Input() set questionsList(questions: Question[]) {
    this._questionsList = questions;
  }

  /**
   * Question List.
   * @returns Question List.
   */
  get questionsList(): Question[] {
    return this._questionsList;
  }

  /** Disable the section to add values. */
  @Input() set disableAppendValue(value: boolean) {
    /** It should disable the form in case there is no base field selected. */
    value
      ? this.formAppendedField.controls.values.disable()
      : this.formAppendedField.controls.values.enable();
  }

  /** Emits the event to remove the current value. */
  @Output() editedAppendedField = new EventEmitter<string>();

  /** Emits the event to validate the current value. */
  @Output() validateEmptyValue = new EventEmitter<string>();

  /** Emits the event to reset the current value. */
  @Output() resetAppend = new EventEmitter<void>();

  /** Emits the event to remove the current value.*/
  @Output() removeAppendedField = new EventEmitter<string>();

  /** Emits the event to remove the custom value or not.*/
  @Output() isCustomValue = new EventEmitter<boolean>();

  /** Form for appended field. */
  formAppendedField = new FormGroup({
    values: new FormControl<string>(''),
  });

  /** Contains whether the value is bucket or custom. */
  showCustomValue = true;

  /** The enum question field type. */
  questionFieldType = QuestionFieldType;

  /** Flag to enable or assigned to value from bucket list. */
  isAssignedToEnable = false;

  constructor() {
    effect(() => {
      this.isAssignedToEnable = this.showAssignedTo();
    });
  }

  /** Initial lifecycle hook. */
  ngOnInit(): void {
    this.addAppendFieldData();
  }

  /**
   * Assign the append field value to the form control.
   */
  addAppendFieldData(): void {
    let appendValue = this.appendedValue;
    const prefixActions = {
      'rid:': this.appendedValue.substring(4),
      'info:': this.appendedValue,
      'assignedTo:': this.appendedValue.replace('assignedTo:', ''),
    };

    for (const [prefix, value] of Object.entries(prefixActions)) {
      if (this.appendedValue.includes(prefix)) {
        appendValue = value;
        this.showCustomValue = false;
        break;
      }
    }
    this.formAppendedField.controls.values.setValue(appendValue);
  }

  /**
   * Edit the value selected or added in the section of `Append Values`.
   * @param value Contains the value that comes from the bucket question select or from the custom value input.
   */
  editAppendedValue(value: string): void {
    /** Assign the value that comes from the input of custom-value or the select of bucket question. */
    const valueSelected = value;
    let appendedValue = valueSelected;
    if (!this.showCustomValue) {
      if (valueSelected === this.questionFieldType.AssignedUser) {
        appendedValue = 'assignedTo:' + valueSelected;
      } else {
        appendedValue = 'rid:' + valueSelected;
      }
    }

    /** Emits the event to the parent for editing in the parent list of added values. */
    this.editedAppendedField.emit(appendedValue);
  }

  /**
   * Edit the value selected or added in the section of `Append Values`.
   * @param value Contains the value that comes from the bucket question select or from the custom value input.
   */
  validateEmpty(value: string): void {
    this.validateEmptyValue.emit(value);
    this.isCustomValue.emit(this.showCustomValue);
  }

  /**
   * Edit the value selected or added in the section of `Append Values`.
   * @param question Question to append.
   * @returns RithmId or Key.
   */
  getQuestionValue(question: Question | CustomField): string {
    return (
      (question as Question)?.rithmId ||
      (question as CustomField)?.questionType ||
      ''
    );
  }

  /**
   * Remove added field.
   */
  removeAppendField(): void {
    this.removeAppendedField.emit(this.appendedValue);
    this.isCustomValue.emit(this.showCustomValue);
  }

  /**
   * Reset the added value in case of clicking the button (bucket or custom).
   *
   */
  resetAppendValue(): void {
    this.showCustomValue = !this.showCustomValue;
    this.formAppendedField.reset();
    this.editedAppendedField.emit('');
    this.validateEmptyValue.emit('');
    this.resetAppend.emit();
    this.isCustomValue.emit(this.showCustomValue);
  }
}
