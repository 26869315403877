import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import {
  ActionType,
  ChatGptActionInfo,
  PowerAction,
  Question,
} from 'src/models';
import { ConditionHelper } from 'src/helpers';
import { Subject, takeUntil } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

/** Chat GPT Form component. */
@Component({
  selector: 'app-chat-gpt-form[action][stationRithmId]',
  templateUrl: './chat-gpt-form.component.html',
  styleUrls: ['./chat-gpt-form.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSelectModule,
    MatInputModule,
  ],
})
export class ChatGptFormComponent implements OnDestroy {
  /** Helper for conditions. */
  conditionHelper = ConditionHelper;

  /** Observable for when the component is destroyed. */
  private destroyed$ = new Subject<void>();

  /** Contains the action of the power that will be edited. */
  private _actionToUpdate!: PowerAction | null;

  /** The station id used to get shared values. */
  @Input() stationRithmId!: string;

  /** Contains the action of the power that will be edited. */
  @Input() set action(value: PowerAction | null) {
    this._actionToUpdate = value;
    this.setInformationToEdit();
  }

  /** Bucket questions list. */
  @Input() set bucketQuestions(bucket: Question[]) {
    this._bucketQuestions = bucket;
    this.filterChatGptFields();
  }

  /** Feature flag order of operations. */
  @Input() orderOfOperations = false;

  /** Emit the event when form data changes. */
  @Output() isFormValid = new EventEmitter<boolean>();

  /** Emit the new action to add. */
  @Output() chatGptPowerEmitter = new EventEmitter<PowerAction>();

  /** Bucket questions. */
  _bucketQuestions: Question[] = [];

  /** List of Chat GPT fields. */
  filteredQuestions: Question[] = [];

  /** Integration form. */
  integrationForm = new FormGroup({
    prompt: new FormControl<string>('', {
      validators: [Validators.minLength(2), Validators.required],
    }),
    fieldRithmId: new FormControl<string>('', {
      validators: [Validators.minLength(2), Validators.required],
    }),
  });

  /** Determine if a prompt should be custom field or bucket question field. */
  isCustomField = true;

  /** Selected prompt field data. */
  selectedPrompt: Question | null = null;

  /** Selected response field data. */
  selectedResponse!: Question;

  constructor() {
    this.subscribeIntegrationForm();
  }

  /**
   * Listen for changes to the Integration form.
   */
  private subscribeIntegrationForm(): void {
    this.integrationForm.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.isFormValid.emit(this.integrationForm.valid);
      });
  }

  /**
   * Reset prompt object values.
   */
  resetValues(): void {
    this.isCustomField = !this.isCustomField;
    this.integrationForm.controls.prompt.setValue('');
    this.selectedPrompt = null;
  }

  /**
   * Filter bucket questions to list short, long text and container name.
   *
   */
  filterChatGptFields(): void {
    this.filteredQuestions = this.conditionHelper.getChatGPTFields(
      this._bucketQuestions,
    ) as Question[];
  }

  /**
   * Change the type of widget selected.
   * @param option Option selected.
   */
  selectPromptField(option: MatSelectChange): void {
    const index = this.filteredQuestions.findIndex(
      (que) => que.rithmId === option.value,
    );
    this.selectedPrompt = this.filteredQuestions[index];
  }

  /**
   * Change the type of widget selected.
   * @param option Option selected.
   */
  selectResponseField(option: MatSelectChange): void {
    const index = this.filteredQuestions.findIndex(
      (que) => que.rithmId === option.value,
    );
    this.selectedResponse = this.filteredQuestions[index];
  }

  /**
   * Saving action type changes.
   *
   */
  public addUpdateIntegrationAction(): void {
    const chatGptActionInfo: ChatGptActionInfo = {
      type: ActionType.ChatGPT,
      prompt: this.integrationForm.controls.prompt.value || '',
      isCustomText: this.isCustomField,
      promptDetails: this.selectedPrompt || null,
      response: this.integrationForm.controls.fieldRithmId.value || '',
      responseDetails: this.selectedResponse,
    };

    const createdAction: PowerAction = {
      order: 1,
      rithmId: this._actionToUpdate ? this._actionToUpdate.rithmId : uuidv4(),
      type: ActionType.ChatGPT,
      target: this.stationRithmId,
      data: JSON.stringify(chatGptActionInfo),
      resultMapping: '',
      header: '',
    };
    this.chatGptPowerEmitter.emit(createdAction);
  }

  /**
   * This method will set the information to edit an already created action.
   */
  private setInformationToEdit(): void {
    if (this._actionToUpdate && this._actionToUpdate.data) {
      const actionData = this._actionToUpdate.data.replace(/'/g, ' ');
      const parsedData = JSON.parse(actionData) as ChatGptActionInfo;
      this.selectedPrompt = parsedData.promptDetails;
      this.selectedResponse = parsedData.responseDetails;
      this.isCustomField = parsedData.isCustomText;
      this.integrationForm.controls.prompt.setValue(parsedData.prompt);
      this.integrationForm.controls.fieldRithmId.setValue(parsedData.response);
    }
  }

  /**
   * Completes all subscriptions.
   */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
