<section
  class="append-field-section flex w-full justify-between items-center space-x-1"
  [formGroup]="formAppendedField"
>
  <div class="flex space-x-2 w-full min-w-[340px] flex-shrink-0">
    @if (!showCustomValue) {
      <mat-form-field
        appearance="outline"
        class="w-full"
        subscriptSizing="dynamic"
      >
        <mat-label>Bucket Fields</mat-label>
        <mat-select
          formControlName="values"
          (selectionChange)="editAppendedValue($event.value)"
        >
          @for (question of questionsList; track $index) {
            <mat-option [value]="question.rithmId">
              {{ question.prompt }}
            </mat-option>
          }
          @if (isAssignedToEnable) {
            <mat-option [value]="questionFieldType.AssignedUser">
              {{ 'Assigned to' }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    } @else {
      <ng-container *ngTemplateOutlet="customValue"></ng-container>
    }

    <ng-template #customValue>
      <mat-form-field
        appearance="outline"
        subscriptSizing="dynamic"
        class="w-full"
      >
        <mat-label>Custom value</mat-label>
        <input
          matInput
          #inputCustomValue
          formControlName="values"
          (change)="editAppendedValue(inputCustomValue.value)"
          (keyup)="validateEmpty(inputCustomValue.value)"
        />
      </mat-form-field>
    </ng-template>
    <button
      mat-stroked-button
      type="button"
      id="switch-append-value"
      data-testid="switch-append-value"
      (click)="resetAppendValue()"
    >
      <i
        class="fa-solid text-accent-500 fa-xl"
        [ngClass]="!showCustomValue ? 'fa-keyboard' : 'fa-angle-down'"
      ></i>
    </button>
  </div>

  <button
    mat-icon-button
    type="button"
    color="warn"
    class="remove-item hover:text-error-500"
    id="remove-item"
    data-testid="remove-item"
    (click)="removeAppendField()"
  >
    <i class="fa-light fa-trash text-primary-400"></i>
  </button>
</section>
