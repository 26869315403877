@if (!bunchOfQuestionsLoading) {
  <div class="update-field-action pb-5">
    <h6 class="mb-4 text-lg">Update Field</h6>
    <section class="update-field-form" [formGroup]="updateFieldForm">
      <section class="field-to-update">
        <mat-form-field
          appearance="outline"
          class="w-full"
          subscriptSizing="dynamic"
        >
          <mat-label class="text-base">Select Field</mat-label>
          <mat-select
            (selectionChange)="changeFirstField = true"
            formControlName="fieldToUpdate"
          >
            <mat-select-trigger>
              @if (selectedParentPrompt !== 'assigned to') {
                <span
                  [ngClass]="{
                    'text-secondary-100': selectedChildPrompt.length
                  }"
                  >{{ selectedParentPrompt }}</span
                >

                @if (selectedChildPrompt.length) {
                  <span> / {{ selectedChildPrompt }}</span>
                }

                @if (
                  selectedParentPrompt.length || selectedChildPrompt.length
                ) {
                  <span class="text-secondary-200">
                    ({{ titleCaseType(selectedChildType) }})
                  </span>
                }
              }

              @if (selectedParentPrompt === 'assigned to') {
                <span>{{ selectedParentPrompt }}</span>
              }
            </mat-select-trigger>

            @for (field of staticFields; track $index) {
              <mat-option
                [id]="'field-' + field.key"
                [attr.data-testid]="'field-' + field.key"
                [value]="field.key"
                (click)="selectStaticField(field)"
                >{{ field.value }}
              </mat-option>
            }
            @for (field of purgedFieldsFromBucket; track $index) {
              @if (
                field.questionType !== fieldTypes.AddressLine &&
                field.questionType !== fieldTypes.CustomId
              ) {
                <mat-option
                  [id]="'field-' + field.rithmId"
                  [attr.data-testid]="'field-' + field.rithmId"
                  [value]="field.rithmId"
                  (click)="selectingFieldToUpdate(field.rithmId)"
                  >{{ field.prompt }}
                  <span class="text-secondary-200">
                    ({{ titleCaseType(field.questionType) }})
                  </span>
                </mat-option>
              } @else {
                <mat-optgroup [label]="field.prompt">
                  @for (child of field.children; track $index) {
                    <mat-option
                      [id]="'field-' + child.rithmId"
                      [attr.data-testid]="'field-' + child.rithmId"
                      [value]="child.rithmId"
                      (click)="
                        selectingFieldToUpdate(field.rithmId, true, child)
                      "
                    >
                      @if (
                        updateFieldForm.value.fieldToUpdate === child.rithmId
                      ) {
                        <span> {{ field.prompt }}</span>
                      }
                      {{ child.prompt }}
                      <span class="text-secondary-200"
                        >({{ titleCaseType(child.questionType) }})</span
                      >
                    </mat-option>
                  }
                </mat-optgroup>
              }
            }
          </mat-select>
        </mat-form-field>
      </section>
      @if (updateFieldForm.value.fieldToUpdate) {
        <section class="field-updates mt-4">
          <p class="my-3">Updates to</p>
          @if (!showFieldsSection && !showOptionsUpdateTo) {
            <div class="flex items-start justify-between space-x-2">
              <ng-container
                *ngTemplateOutlet="
                  isCustomValues && selectedFieldType !== fieldTypes.File
                    ? customValues
                    : fieldValues;
                  context: { field: questionCopy }
                "
              />
              @if (
                selectedFieldType !== fieldTypes.UserSelect &&
                selectedFieldType !== fieldTypes.File
              ) {
                <button
                  mat-stroked-button
                  type="button"
                  color="accent"
                  id="updates-option-btn"
                  data-testid="updates-option-btn"
                  class="value-switching-button"
                  (click)="switchFieldToUpdate()"
                  [disabled]="bunchOfQuestionsLoading"
                >
                  <i
                    class="fa-light fa-xl"
                    [ngClass]="
                      isCustomValues ? 'fa-keyboard' : 'fa-chevron-down'
                    "
                  ></i>
                </button>
              }
            </div>
          }
          @if (showOptionsUpdateTo && !showFieldsSection) {
            <div>
              <mat-form-field
                appearance="outline"
                class="w-full"
                subscriptSizing="dynamic"
              >
                <mat-label class="text-base">Options</mat-label>
                <mat-select formControlName="valueToUpdateField">
                  <mat-option
                    [id]="'unassigned-option'"
                    [attr.data-testid]="'unassigned-option'"
                    [value]="'unassigned'"
                  >
                    <span class="pl-4">unassigned</span>
                  </mat-option>

                  <mat-optgroup label="custom fields">
                    @for (field of customFields; track $index) {
                      <mat-option
                        [id]="'field-' + field.key"
                        [attr.data-testid]="'field-' + field.key"
                        [value]="field.key"
                      >
                        {{ field.value }}
                      </mat-option>
                    }
                  </mat-optgroup>

                  <mat-optgroup label="member worker">
                    @for (member of stationRosterMember; track $index) {
                      <mat-option
                        [id]="'member-' + member.rithmId"
                        [attr.data-testid]="'member-' + member.rithmId"
                        [value]="member.rithmId"
                      >
                        <div class="flex space-x-1 items-centerri">
                          <app-user-avatar
                            [firstName]="member.firstName"
                            [lastName]="member.lastName"
                            [size]="'30'"
                            [profileImageRithmId]="
                              member.profileImageRithmId || ''
                            "
                          ></app-user-avatar>
                          <span>{{
                            member.firstName + ' ' + member.lastName
                          }}</span>
                        </div>
                      </mat-option>
                    }
                    @if (!stationRosterMember.length) {
                      <mat-option class="text-sm" [disabled]="true">
                        -- N/A --
                      </mat-option>
                    }
                  </mat-optgroup>
                </mat-select>
              </mat-form-field>
              <p class="text-primary-300 text-xs">
                *users not on the
                {{ termsGeneric.Station.Single | lowercase }} member roster will
                not be assigned
              </p>
            </div>
          }
        </section>
      }
    </section>
    <ng-container *ngTemplateOutlet="controlButtons" />
  </div>
} @else {
  <div class="flex justify-start items-center h-12">
    <app-loading-indicator inlineText="Loading fields..." />
  </div>
}

<!-- Message displayed when there are not fields saved in the station grid. -->
<ng-template #noFieldsAdded>
  <div class="flex items-center justify-start h-10 text-secondary-100">
    <p>
      There are not fields added saved in the
      {{ termsGeneric.Station.Single | lowercase }} grid
    </p>
  </div>
</ng-template>

<ng-template #controlButtons>
  <section
    class="container-action-control-buttons space-x-4"
    [ngClass]="{
      'relative flex justify-between items-center py-2 max-w-none':
        orderOfOperations && editingAction
    }"
  >
    <button
      mat-button
      [disabled]="(savingAction || deletingAction) && orderOfOperations"
      type="button"
      (click)="cancelAction()"
      data-testid="action-cancel"
      id="action-cancel"
    >
      <span class="font-semibold">CANCEL</span>
    </button>
    <button
      mat-raised-button
      [ngClass]="{
        '!bg-sky-500 !text-white !font-semibold': orderOfOperations,
        '!opacity-60':
          !updateFieldForm.valid ||
          !updateFieldForm.value.valueToUpdateField?.toString()?.trim() ||
          ((savingAction || deletingAction) && orderOfOperations)
      }"
      type="button"
      color="accent"
      [disabled]="
        !updateFieldForm.valid ||
        !updateFieldForm.value.valueToUpdateField?.toString()?.trim() ||
        ((savingAction || deletingAction) && orderOfOperations)
      "
      id="action-save"
      data-testid="action-save"
      (click)="addUpdatedField()"
    >
      @if (orderOfOperations) {
        @if (savingAction) {
          <ng-container
            [ngTemplateOutlet]="loadingInlineText"
            [ngTemplateOutletContext]="{ $implicit: 'Saving...' }"
          ></ng-container>
        } @else {
          SAVE
        }
      } @else {
        DONE
      }
    </button>

    @if (orderOfOperations && editingAction) {
      <div class="flex-grow text-right">
        <button
          mat-stroked-button
          [disabled]="savingAction || deletingAction"
          class="!bg-red-500 !text-white"
          type="button"
          id="delete-action"
          data-testid="delete-action"
          (click)="removeParentAction()"
        >
          @if (deletingAction) {
            <ng-container
              [ngTemplateOutlet]="loadingInlineText"
              [ngTemplateOutletContext]="{ $implicit: 'Deleting...' }"
            ></ng-container>
          } @else {
            <i class="fa-light fa-trash text-lg"></i> DELETE ACTION
          }
        </button>
      </div>
    }
  </section>
</ng-template>

<!-- Updates to custom values -->
<ng-template #customValues let-field="field">
  <section class="custom-values w-full">
    @if (isTextField(field.questionType)) {
      <app-text-field
        [field]="field"
        [isAction]="true"
        [isStation]="false"
        [labelTag]="field.prompt"
        [isInput]="true"
      />
    }
    @if (isNumberField(field.questionType)) {
      <app-number-field [field]="field" [isAction]="true" />
    }

    @if (isDateField(field.questionType)) {
      <app-date-field [field]="field" [isAction]="true" />
    }
    @if (isSelectionField(field.questionType) && !isLoadingUser) {
      <app-select-field
        [field]="field"
        [isAction]="true"
        [stationId]="stationRithmId"
        [isPreviousField]="field.originalStationRithmId !== stationRithmId"
      />
    }
  </section>
</ng-template>

<!-- Updates to field values -->
<ng-template #fieldValues>
  <section class="w-full" [formGroup]="updateFieldForm">
    <mat-form-field
      class="w-full"
      appearance="outline"
      subscriptSizing="dynamic"
    >
      <mat-label class="text-base">Field Value</mat-label>
      <mat-select formControlName="valueToUpdateField">
        <mat-select-trigger>
          <span
            [ngClass]="{
              'text-secondary-100': selectedValueChildPrompt.length
            }"
            >{{ selectedValueParentPrompt }}</span
          >
          @if (selectedValueChildPrompt.length) {
            <span> / {{ selectedValueChildPrompt }}</span>
          }
          @if (
            (selectedValueParentPrompt.length ||
              selectedValueChildPrompt.length) &&
            selectedValueParentPrompt !== '--no value--'
          ) {
            <span class="text-secondary-200">
              ({{ titleCaseType(secondSelectedField.questionType) }})
            </span>
          }
        </mat-select-trigger>
        <mat-option
          [id]="'field-empty'"
          [attr.data-testid]="'field-empty'"
          [value]="'--no value--'"
          (click)="selectingFieldValue('')"
          >-- no value --
        </mat-option>
        @for (field of filteredFromBucket; track $index) {
          @if (field.questionType !== fieldTypes.AddressLine) {
            <mat-option
              [id]="'field-' + field.rithmId"
              [attr.data-testid]="'field-' + field.rithmId"
              [value]="field.rithmId"
              (click)="selectingFieldValue(field.rithmId)"
              >{{ field.prompt }}
              <span class="text-secondary-200">
                ({{ titleCaseType(field.questionType) }})
              </span>
            </mat-option>
          } @else {
            <mat-optgroup [label]="field.prompt">
              @for (child of filteredAddressChildren(field); track $index) {
                <mat-option
                  [id]="'field-' + child.rithmId"
                  [attr.data-testid]="'field-' + child.rithmId"
                  [value]="child.rithmId"
                  (click)="selectingFieldValue(field.rithmId, child)"
                >
                  @if (updateFieldForm.value.fieldToUpdate === child.rithmId) {
                    <span> {{ field.prompt }}</span>
                  }
                  {{ child.prompt }}
                  <span class="text-secondary-200"
                    >({{ titleCaseType(child.questionType) }})</span
                  >
                </mat-option>
              }
            </mat-optgroup>
          }
        }
      </mat-select>
    </mat-form-field>
  </section>
</ng-template>

<!-- In line text loading indicator -->
<ng-template #loadingInlineText let-message>
  <app-loading-indicator
    id="loading-inline-text"
    [inlineText]="message"
  ></app-loading-indicator>
</ng-template>
