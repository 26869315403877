import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ActionType,
  ColumnOfTableIntegrationSql,
  IntegrationSql,
  PowerAction,
  Question,
  QuestionFieldType,
  SqlActionData,
  SqlIntegrationType,
  TableColumnPair,
  TableIntegrationType,
} from 'src/models';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { CustomVsBucketQuestionComponent } from 'src/app/shared/custom-vs-bucket/custom-vs-bucket.component';
import { IntegrationService } from 'src/app/core/integration.service';
import { Subject, first, forkJoin, of, takeUntil } from 'rxjs';
import { LoadingIndicatorComponent } from 'src/app/shared/loading-indicator/loading-indicator.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PopupService } from 'src/app/core/popup.service';
import { NumberFieldComponent } from 'src/app/shared/fields/number-field/number-field.component';
import { DateFieldComponent } from 'src/app/shared/fields/date-field/date-field.component';
import { TextFieldComponent } from 'src/app/shared/fields/text-field/text-field.component';
import { CheckFieldComponent } from 'src/app/shared/fields/check-field/check-field.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ConditionHelper } from 'src/helpers';

/** SQL FORM to add any database connection action.*/
@Component({
  selector: 'app-sql-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    CustomVsBucketQuestionComponent,
    MatButtonModule,
    LoadingIndicatorComponent,
    MatProgressSpinnerModule,
    NumberFieldComponent,
    TextFieldComponent,
    DateFieldComponent,
    CheckFieldComponent,
    MatFormFieldModule,
    MatTooltipModule,
  ],
  templateUrl: './sql-form.component.html',
  styleUrls: ['./sql-form.component.scss'],
})
export class SqlFormComponent implements OnInit, OnDestroy {
  /** Helper for conditions. */
  conditionHelper = ConditionHelper;

  /** Subject that emits when the component has been destroyed. */
  protected _destroyed$ = new Subject<void>();

  /** The station id used to get shared values. */
  @Input() stationRithmId!: string;

  /** Contains the action of the power that will be edited. */
  @Input() set action(value: PowerAction | null) {
    if (value) {
      this.editMode = true;
      this._actionToUpdate = value;
      this.setInformationToEdit();
    }
  }

  /** Bucket questions list. */
  @Input() set bucketQuestions(bucket: Question[]) {
    // Filter question data.
    this._bucketQuestions = [
      ...this.conditionHelper.getStationQuestions(bucket),
      ...this.conditionHelper.getContainerInfoQuestions(bucket),
    ];
    // Ascending order.
    this._bucketQuestions = this._bucketQuestions.sort((a, b) =>
      a.prompt < b.prompt ? -1 : a.prompt > b.prompt ? 1 : 0,
    );
  }

  /** PUSH/PULL type. */
  @Input() sqlSelected!: SqlIntegrationType | null;

  /** Emit the new action to add. */
  @Output() sqlPowerEmitter = new EventEmitter<PowerAction>();

  /** Emits the current status of the form. */
  @Output() sqlFormStatus = new EventEmitter<boolean>();

  /** Contains the action of the power that will be edited. */
  private _actionToUpdate!: PowerAction | null;

  /** Bucket questions. */
  private _bucketQuestions: Question[] = [];

  /** Whether the sql form is in edit mode or not. */
  editMode = false;

  /** Integration sql form . */
  sqlIntegrationForm = new FormGroup({
    dataBase: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    tableName: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    keyField: new FormGroup({
      columnKey: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      fieldKey: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
    }),
    CustomValue: new FormArray<
      FormGroup<{
        /** Column.*/
        columnCustom: FormControl<string | null>;
        /** Field.*/
        fieldCustom: FormControl<string | null>;
      }>
    >([
      new FormGroup({
        columnCustom: new FormControl<string | null>('', {
          validators: [Validators.required],
        }),
        fieldCustom: new FormControl<string | null>('', {
          validators: [Validators.required],
        }),
      }),
    ]),
  });

  /** Array of tablePairs to GET/POST. */
  columnValuePairs: TableColumnPair[] = [
    {
      column: '<column1>',
      field: '<field1>',
    },
  ];

  /** Default question for use in the view. */
  field: Question = {
    rithmId: uuidv4(),
    prompt: 'Field',
    questionType: QuestionFieldType.ShortText,
    isReadOnly: false,
    isRequired: true,
    isPrivate: false,
    possibleAnswers: [],
    children: [],
    answer: {
      questionRithmId: '',
      referAttribute: '',
      value: '',
      asString: '',
      asArray: [],
      asInt: 0,
      asJson: undefined,
      asBoolean: false,
      asDate: '',
    },
  };

  /** List of columns of tables of Integration SQL. */
  columnsOfTablesIntegrationSql: ColumnOfTableIntegrationSql[] = [];

  /** RithmIds of Integration SQL. */
  integrationRithmId = '';

  /** Table name of Integration SQL. */
  tableName = '';

  /** List of sql integration databases. */
  databasesList: string[] = [];

  /** List of non-foreign and non-primary database columns. */
  columnsList: ColumnOfTableIntegrationSql[] = [];

  /** List of sql integration tables. */
  tablesList: string[] = [];

  /** Show or hidden databases name list while loading. */
  showDatabaseLoading = false;

  /** Show or hidden tables name list while loading. */
  showTableLoading = false;

  /** Show or hidden columns name list while loading. */
  showColumnLoading = false;

  /** List of filtered questions for key field. */
  filteredQuestionsForKeyField: Question[] = [];

  /** LList of filtered multi-questions for custom field. */
  filteredQuestionsForCustomField: Question[][] = [];

  /** Determine what fields are currently in the form condition the previous fields. */
  isCustomField = false;

  /** The minimum date value to be set for date-picker. */
  minDate = new Date();

  /** List of integrations sql. */
  integrationsSqlList: IntegrationSql[] = [];

  /** The filter to get respective question from bucket list. */
  filteredByIntegrationType!: TableIntegrationType;

  /* Whether the entire formArray of the CustomValue is complete or not. */
  allCustomValueFilled = false;

  /* Whether the complete sql integration form is loading or not. */
  loadingSqlForm = false;

  /** The form for adding fields to the integration. */
  customFieldForm!: FormGroup<{
    /** Form control field textFieldForm. */
    textFieldForm: FormControl<string | null>;
    /** Form control field numberFieldForm. */
    numberFieldForm: FormControl<string | null>;
    /** Form control field dateFieldForm. */
    dateFieldForm: FormControl<string | null>;
    /** Form control field checkFieldForm. */
    checkFieldForm: FormControl<string | null>;
  }>;

  constructor(
    private integrationService: IntegrationService,
    private popupService: PopupService,
    private fb: FormBuilder,
  ) {
    this.customFieldForm = this.fb.group({
      textFieldForm: this.fb.control(''),
      numberFieldForm: this.fb.control(''),
      dateFieldForm: this.fb.control(''),
      checkFieldForm: this.fb.control(''),
    });
  }

  /**
   * Listen for changes to emit the status of the form.
   */
  private subscribeSqlIntegrationForm(): void {
    this.sqlIntegrationForm.valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe(() => {
        const valid =
          this.sqlIntegrationForm.valid && this.sqlIntegrationForm.dirty;
        this.sqlFormStatus.emit(valid);
      });
  }

  /**
   * Listen to customValue changes.
   */
  private subscribeCustomValue(): void {
    // Listen for changes to disable the add field button.
    this.sqlIntegrationForm.controls['CustomValue'].valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe(() => this.disabledAddFieldButton());
  }

  /**
   * Listen to customFormField changes.
   */
  private subscribeCustomFormField(): void {
    // Listen for changes to disable the add field button.
    this.customFieldForm.valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe(() => {
        this.setCustomFieldValue();
      });
  }

  /**
   * Initial Method.
   */
  ngOnInit(): void {
    this.subscribeSqlIntegrationForm();
    this.subscribeCustomValue();
    this.subscribeCustomFormField();

    if (this.editMode) {
      this.getSqlData(true, true, true);
    } else {
      this.getSqlData(true);
    }
  }

  /**
   * Get data from sql form 'simultaneous requests'.
   * @param getIntegrations  When getting and adding the list of databases from sql integrations.
   * @param getTables When you need to get columns from Integration SQL tables.
   * @param getColumns When it is necessary to get columns from Integration SQL tables.
   */
  getSqlData(
    getIntegrations = false,
    getTables = false,
    getColumns = false,
  ): void {
    // If all requests are made, the form is fully loaded.
    if (getIntegrations && getTables && getColumns) {
      this.loadingSqlForm = true;
    } else {
      if (getIntegrations) {
        this.showDatabaseLoading = true;
        this.sqlIntegrationForm.get('dataBase')?.disable();
      }
      if (getTables) {
        this.showTableLoading = true;
        this.sqlIntegrationForm.get('tableName')?.disable();
      }
      if (getColumns) {
        this.showColumnLoading = true;
        this.sqlIntegrationForm.get('keyField')?.get('columnKey')?.enable();
        this.columnCustomEnable();
      }
    }
    // Database or integrations only.
    const getIntegrationsSql$ = getIntegrations
      ? this.integrationService.getIntegrationsSql()
      : of(null);
    // Get tables from the DB.
    const getTablesSqlIntegrations$ = getTables
      ? this.integrationService.getTablesSqlIntegrations(
          this.integrationRithmId,
        )
      : of(null);
    // Get columns from the selected table.
    const getColumns$ = getColumns
      ? this.integrationService.getColumnsOfTablesIntegrationSql(
          this.integrationRithmId,
          this.tableName,
        )
      : of(null);

    forkJoin([getIntegrationsSql$, getTablesSqlIntegrations$, getColumns$])
      .pipe(first())
      .subscribe({
        next: (response) => {
          // Databases.
          const integrations = response[0] as IntegrationSql[];
          if (integrations) {
            // Ascending order.
            this.integrationsSqlList = integrations.sort((a, b) =>
              a.name < b.name ? -1 : a.name > b.name ? 1 : 0,
            );
            this.showDatabaseLoading = false;
            this.sqlIntegrationForm.get('dataBase')?.enable();
            integrations.forEach((integration: IntegrationSql) => {
              this.databasesList.push(integration.databaseName);
            });
          }
          // Tables.
          const tablesList = response[1] as string[];
          if (tablesList) {
            this.tablesList = tablesList.sort();
            this.showTableLoading = false;
            this.sqlIntegrationForm.get('tableName')?.enable();
          }
          // Columns of the table.
          const columnOfTable = response[2] as ColumnOfTableIntegrationSql[];
          if (columnOfTable) {
            this.columnsOfTablesIntegrationSql = [];
            this.columnsOfTablesIntegrationSql = columnOfTable.sort((a, b) =>
              a.property < b.property ? -1 : a.property > b.property ? 1 : 0,
            );
            this.sqlIntegrationForm.get('keyField')?.get('columnKey')?.enable();
            this.columnsList = [];
            this.columnCustomEnable();
            this.columnsOfTablesIntegrationSql.forEach(
              (column: ColumnOfTableIntegrationSql) => {
                if (!column.isPrimaryKey && !column.isIndex) {
                  this.columnsList.push(column);
                }
              },
            );
            this.showColumnLoading = false;
          }
          // If all of them are done, the key is reset, the columns per key field are reset and the load is cancelled.
          if (getIntegrations && getTables && getColumns) {
            this.columnKeyChange();
            this.resetQuestionsByCustomField();
            this.loadingSqlForm = false;
          }
        },
        error: () => {
          // Database.
          this.showDatabaseLoading = false;
          this.sqlIntegrationForm.get('dataBase')?.enable();
          // Tables.
          this.showTableLoading = false;
          this.sqlIntegrationForm.get('tableName')?.enable();
          // Columns.
          this.showColumnLoading = false;
          this.popupNotify();
        },
      });
  }

  /**
   * This method will set the information to edit an already created action.
   */
  private setInformationToEdit(): void {
    if (this._actionToUpdate && this._actionToUpdate.data) {
      const actionData = this._actionToUpdate.data.replace(/'/g, '"');
      const parsedData = JSON.parse(actionData) as SqlActionData;
      this.integrationRithmId = parsedData.integrationRithmId;
      this.sqlIntegrationForm.controls.dataBase.setValue(
        parsedData.integrationRithmId,
      );
      this.tableName = parsedData.tableName;
      this.sqlIntegrationForm.controls.tableName.setValue(parsedData.tableName);
      this.sqlIntegrationForm.controls.keyField.controls.columnKey.setValue(
        parsedData.keyField.column,
      );
      const fieldValue = parsedData.keyField.field;
      const index = fieldValue.indexOf('rid:');
      // If it does not find the index, it is a custom value.
      if (index === -1) {
        this.isCustomField = true;
        this.field.value = fieldValue;
      }
      this.sqlIntegrationForm.controls.keyField.setValue({
        columnKey: parsedData.keyField.column,
        fieldKey: parsedData.keyField.field,
      });
      if (parsedData.columnValues.length) {
        const customValue = this.sqlIntegrationForm.get(
          'CustomValue',
        ) as FormArray;
        customValue.clear();
        this.columnValuePairs = [];
        parsedData.columnValues.forEach((itemColumn) => {
          this.addField(itemColumn);
        });
      }
    }
  }

  /**
   * Method to empty and reset the CustomValue array form.
   */
  empyCustomValue(): void {
    const customValue = this.sqlIntegrationForm.get('CustomValue') as FormArray;
    for (let i = customValue.length - 1; i >= 0; i--) {
      if (i === 0) {
        customValue.reset();
      } else {
        customValue.removeAt(i);
        this.columnValuePairs.splice(i, 1);
      }
    }
  }

  /**
   * Method that changed value of name database.
   */
  databaseChange(): void {
    this.sqlIntegrationForm.get('tableName')?.reset();
    this.sqlIntegrationForm.get('keyField')?.get('columnKey')?.disable();
    this.sqlIntegrationForm.get('keyField')?.get('columnKey')?.reset();
    if (this.isCustomField) {
      this.changeCustomField();
    } else {
      this.sqlIntegrationForm.get('keyField')?.get('fieldKey')?.reset();
    }
    this.empyCustomValue();
    this.columnCustomDisable();
    this.integrationRithmId =
      this.sqlIntegrationForm.get('dataBase')?.value || '';
    // Only call the getTablesSqlIntegrations request.
    this.getSqlData(false, true);
    this.filteredQuestionsForKeyField = [];
    this.filteredQuestionsForCustomField = [];
  }

  /**
   * Method that changed value of tableName.
   */
  tableNameChange(): void {
    this.sqlIntegrationForm.get('keyField')?.get('columnKey')?.reset();
    if (this.isCustomField) {
      this.changeCustomField();
    } else {
      this.sqlIntegrationForm.get('keyField')?.get('fieldKey')?.reset();
    }
    this.empyCustomValue();
    this.filteredQuestionsForKeyField = [];
    this.filteredQuestionsForCustomField = [];
    this.tableName = this.sqlIntegrationForm.get('tableName')?.value || '';
    // Only call the getColumnsOfTablesIntegrationSql request.
    this.getSqlData(false, false, true);
  }

  /**
   * Method to enable the columnCustom form.
   */
  columnCustomEnable(): void {
    const customValue = this.sqlIntegrationForm.get('CustomValue') as FormArray;
    customValue.controls.forEach((control) => {
      control.get('columnCustom')?.enable();
    });
  }

  /**
   * Method to disable the columnCustom form.
   */
  columnCustomDisable(): void {
    const customValue = this.sqlIntegrationForm.get('CustomValue') as FormArray;
    customValue.controls.forEach((control) => {
      control.get('columnCustom')?.disable();
    });
  }

  /**
   * This method brings the notification message when a SQL request fails.
   */
  popupNotify(): void {
    this.popupService.notify(
      "Something went wrong on our end and we're looking into it. Please try again in a little while.",
      true,
    );
  }

  /**
   * Questions filtered for field key.
   * @param column Column.
   * @param index The index for filtering the Question matrix.
   */
  filteredQuestions(column: ColumnOfTableIntegrationSql, index = -1): void {
    let questionTypes: QuestionFieldType[];
    switch (column.type) {
      case TableIntegrationType.String:
        questionTypes = [
          QuestionFieldType.ContainerName,
          QuestionFieldType.ContainerId,
          QuestionFieldType.StationName,
          QuestionFieldType.StationId,
          QuestionFieldType.ShortText,
          QuestionFieldType.LongText,
          QuestionFieldType.ParentStationRithmId,
          QuestionFieldType.ParentContainerRithmId,
          QuestionFieldType.CreatedBy,
          QuestionFieldType.ParentStationLink,
          QuestionFieldType.ParentStationLink,
          QuestionFieldType.URL,
          QuestionFieldType.Email,
          QuestionFieldType.CreatedBy,
        ];
        break;
      case TableIntegrationType.Boolean:
        questionTypes = [
          QuestionFieldType.Checkbox,
          QuestionFieldType.CheckList,
        ];
        break;
      case TableIntegrationType.Date:
        questionTypes = [
          QuestionFieldType.PresentTime,
          QuestionFieldType.TimeInStation,
          QuestionFieldType.CreatedOn,
          QuestionFieldType.Date,
        ];
        break;
      case TableIntegrationType.Number:
        questionTypes = [
          QuestionFieldType.Number,
          QuestionFieldType.Currency,
          QuestionFieldType.Phone,
        ];
        break;
    }
    if (column.isIndex || column.isPrimaryKey || index < 0) {
      this.field.isRequired = true;
      // Display the field type in the view.
      switch (column.type) {
        case TableIntegrationType.Date:
          this.field.questionType = QuestionFieldType.Date;
          break;
        case TableIntegrationType.Number:
          this.field.questionType = QuestionFieldType.Number;
          break;
        case TableIntegrationType.Boolean:
          this.field.questionType = QuestionFieldType.Checkbox;
          this.field.isRequired = false;
          this.field.prompt = 'Check';
          if (this.field.answer) {
            this.field.answer.asBoolean = this.field.value === 'true';
          }
          break;
        default:
          this.field.questionType = QuestionFieldType.ShortText;
          break;
      }
      this.filteredByIntegrationType = column.type;
      this.filteredQuestionsForKeyField = this._bucketQuestions.filter(
        ({ questionType }) => questionTypes.includes(questionType),
      );
    } else {
      this.filteredQuestionsForCustomField[index] =
        this._bucketQuestions.filter(({ questionType }) =>
          questionTypes.includes(questionType),
        );
    }
  }

  /**
   * Method to read the change of columnCustom and filter the questions.
   * @param index The index to filter questions.
   */
  columnCustomChange(index: number): void {
    const customValueControl = this.sqlIntegrationForm.get(
      'CustomValue',
    ) as FormArray;
    const fieldCustomValue = customValueControl
      ?.at(index)
      ?.get('columnCustom')?.value;
    const matchingColumn = this.columnsList.find(
      (column) => column.property === fieldCustomValue,
    );
    if (matchingColumn) {
      // Filter questions by the index of the multi-question matrix.
      this.filteredQuestions(matchingColumn, index);
    }
  }

  /**
   * Method to read the columnKey change and filter questions for options.
   * @param resetCustom Whether or not to reset the custom field.
   */
  columnKeyChange(resetCustom = false): void {
    const keyField = this.sqlIntegrationForm.get('keyField') as FormGroup;
    const columnKeyValue = keyField?.get('columnKey')?.value;
    const matchingColumnKey = this.columnsOfTablesIntegrationSql.find(
      (column) => column.property === columnKeyValue,
    );
    // Nothing is sent, Index -1 to filter the default array of all fields.
    if (matchingColumnKey) this.filteredQuestions(matchingColumnKey);
    if (resetCustom) this.resetCustomField();
  }

  /**
   * Method for add new field and column.
   * @param columnPair The column:value pair.
   */
  addField(columnPair?: TableColumnPair): void {
    const customValue = this.sqlIntegrationForm.get('CustomValue') as FormArray;
    const newGroup = new FormGroup({
      columnCustom: new FormControl<string | null>(columnPair?.column || '', {
        validators: [Validators.required],
      }),
      fieldCustom: new FormControl<string | null>(columnPair?.field || '', {
        validators: [Validators.required],
      }),
    });
    customValue.push(newGroup);
    this.columnValuePairs.push({
      column: '<newColumn>',
      field: '<newField>',
    });
  }

  /**
   * Method for selected field.
   */
  fieldChange(): void {
    const customValue = this.sqlIntegrationForm.get('CustomValue') as FormArray;
    customValue.controls.forEach((control: AbstractControl) => {
      const group = control as FormGroup;
      const columnCustomControl = group.get('fieldCustom');
      if (columnCustomControl) {
        this.columnsList.forEach((column: ColumnOfTableIntegrationSql) => {
          if (column.property === columnCustomControl.value) {
            this.filteredQuestions(column);
          }
        });
      }
    });
  }

  /**
   * Method to reset the filtering of questions by Custom Field.
   */
  resetQuestionsByCustomField(): void {
    const customValueControl = <FormArray>(
      this.sqlIntegrationForm.get('CustomValue')
    );
    // We must reset each of the columns to synchronize the index.
    for (let index = 0; index < this.columnValuePairs.length; index++) {
      const columnCustom = customValueControl
        .at(index)
        ?.get('columnCustom')?.value;
      const currentColumn = this.columnsList.find(
        (column: ColumnOfTableIntegrationSql) =>
          column.property === columnCustom,
      );
      if (currentColumn) {
        this.filteredQuestions(currentColumn, index);
      }
    }
    customValueControl.markAsDirty();
  }

  /**
   * Method for delete field.
   * @param index The array index.
   */
  removeField(index: number): void {
    const customValueControl = <FormArray>(
      this.sqlIntegrationForm.get('CustomValue')
    );
    customValueControl.removeAt(index);
    this.columnValuePairs.splice(index, 1);
    this.resetQuestionsByCustomField();
  }

  /**
   * Method to disable the Add Field button.
   */
  disabledAddFieldButton(): void {
    const customValueArray = this.sqlIntegrationForm.get(
      'CustomValue',
    ) as FormArray;
    this.allCustomValueFilled = customValueArray.valid;
  }

  /**
   * Emit integration to be saved in the station.
   */
  emitIntegrationAction(): void {
    const keyField = this.sqlIntegrationForm.get('keyField') as FormGroup;
    const customValue = this.sqlIntegrationForm.get('CustomValue') as FormArray;
    // Find the name of the database.
    const database = this.integrationsSqlList.find(
      (item) =>
        item.rithmId === this.sqlIntegrationForm.controls['dataBase'].value,
    );
    if (keyField && customValue && database) {
      const keyFieldValue: TableColumnPair = {
        column: keyField.get('columnKey')?.value,
        field: keyField.get('fieldKey')?.value,
      };
      // Extract the even numbered columns for the object to be emitted.
      const columnPairs: TableColumnPair[] = customValue.controls
        .map((control) => {
          const columnCustomControl = control.get('columnCustom');
          const fieldCustomControl = control.get('fieldCustom');
          if (columnCustomControl && fieldCustomControl) {
            return {
              column: columnCustomControl.value,
              field: fieldCustomControl.value,
            };
          }
          return;
        })
        .filter((pair) => pair) as TableColumnPair[];
      // Integration object.
      const sqlIntegrationData: SqlActionData = {
        integrationRithmId: this.integrationRithmId,
        tableName: this.sqlIntegrationForm.controls['tableName'].value,
        keyField: keyFieldValue,
        columnValues: columnPairs,
      };
      // Action object to be saved in the station.
      const sqlPower: PowerAction = {
        order: 1,
        rithmId: this._actionToUpdate ? this._actionToUpdate.rithmId : uuidv4(),
        type:
          this.sqlSelected === SqlIntegrationType.Push
            ? ActionType.SqlPush
            : ActionType.SqlPull,
        target: this.stationRithmId,
        data: JSON.stringify(sqlIntegrationData),
        resultMapping: '',
        header: database.name,
      };
      this.sqlPowerEmitter.emit(sqlPower);
    }
  }

  /**
   * Set custom field value.
   */
  setCustomFieldValue(): void {
    // We extract the value depending on whether it is customized.
    if (this.isCustomField) {
      let customFieldValue = '';
      switch (this.filteredByIntegrationType) {
        case TableIntegrationType.Number: {
          const numberField = this.customFieldForm.controls.numberFieldForm
            .value as unknown as {
            /** Value as unknown or number. */
            number: number;
          };
          if (numberField) {
            customFieldValue = numberField.number?.toString();
          }
          break;
        }
        case TableIntegrationType.Date: {
          const dateField = this.customFieldForm.controls.dateFieldForm
            .value as unknown as {
            /** Value as unknown or date. */
            date: Date;
          };
          const validDateField =
            this.customFieldForm.controls.dateFieldForm.valid;
          if (dateField && dateField.date && validDateField) {
            customFieldValue = dateField.date.toISOString();
          } else {
            customFieldValue = '';
          }
          break;
        }
        case TableIntegrationType.Boolean: {
          const booleanField = this.customFieldForm.controls.checkFieldForm
            .value as unknown as {
            /** Value as unknown or boolean. */
            checkbox: boolean;
          };
          if (booleanField) {
            customFieldValue = (booleanField.checkbox as boolean).toString();
          }
          break;
        }
        default: {
          const textField = this.customFieldForm.controls.textFieldForm
            .value as unknown as {
            /** Value as unknown or string. */
            shortText: string;
          };
          if (textField) {
            customFieldValue = textField.shortText;
          }
          break;
        }
      }
      this.sqlIntegrationForm.controls.keyField.controls.fieldKey.setValue(
        customFieldValue,
      );
    }
  }

  /**
   * Reset fieldKey or the values for the custom custom field.
   */
  resetCustomField(): void {
    const fieldKey =
      this.sqlIntegrationForm.controls.keyField.controls.fieldKey;
    if (this.isCustomField) {
      fieldKey.reset();
      this.field.value = '';
      this.field.prompt = 'Field';
      if (
        this.field.answer &&
        this.field.questionType === QuestionFieldType.Checkbox
      ) {
        this.field.prompt = 'Check';
        this.field.answer.asBoolean = false;
      }
    } else {
      // Minimum waiting time to reflect change.
      setTimeout(() => {
        fieldKey.reset();
      }, 1);
    }
  }

  /**
   * Change custom field, reset values.
   */
  changeCustomField(): void {
    this.isCustomField = !this.isCustomField;
    this.resetCustomField();
  }

  /**
   * Cleanup method.
   */
  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
